import {
  Component,
  OnInit,
  AfterViewInit,
  ViewChild,
  OnDestroy,
  HostListener,
  NgModule,
} from "@angular/core";
import { User } from "../model/user.model";
import { ApiService } from "../core/api.service";
import { Lista2 } from "../model/listaIt.model";
import { MatPaginator, MatPaginatorIntl } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { ErrorHandlerService } from "../core/error-handler.service";
import { Router } from "@angular/router";
import { Observable, Subject } from "rxjs";
import { takeUntil, tap } from "rxjs/operators";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
  FormArray,
  FormControlName,
} from "@angular/forms";
/**
 *  @todo substituir as funções de máscara e format de data pelas funções da lib moment
import moment from "moment";
import "moment/locale/pt-br";
 *
 */

import {
  HttpClient,
  HttpHeaders,
  HttpEvent,
  HttpInterceptor,
  HttpRequest,
  HttpEventType,
  HttpResponse,
  HttpHandler,
  HttpErrorResponse,
  // tslint:disable-next-line: import-spacing
} from "@angular/common/http";
import {
  CurrencyPipe,
  formatCurrency,
} from "@angular/common";
import { MsgdialogService } from "../msgdialog/msgdialog.service";
import { MatDialog } from '@angular/material';
import { UploadFilesComponent } from 'src/app/upload-files/upload-files.component';
import { LoginComponent } from 'src/app/login/login.component'
import { match } from "assert";


export class ListaFilter {
  itcn_CDMATRICULA_CADASTRO: string;
  dk: string;
  itcn_NR_INFOTECNICA: string;
  nucleo_TEC: string;
  campo_2: string;
  dt_ELABORACAO: string;
  opiniao_TECNICA: string;
  mprj: string;
  dias: string;
  itcn_DT_CADASTRO: string;
}

@Component({
  selector: "app-lista",
  templateUrl: "./lista.component.html",
  styleUrls: ["./lista.component.css"],
})

@NgModule({
  declarations: [

    LoginComponent,
    UploadFilesComponent,
    MatDialog
  ],
  entryComponents: [MatDialog, UploadFilesComponent]
})
export class ListaComponent implements OnInit, AfterViewInit {
  data: any[];
  list: any[] = [];
  public now: Date = new Date();
  public dataSource = new MatTableDataSource<Lista2[]>();
  public dataSourceOriginal = [];
  filterEntity: ListaFilter;


  private unsubscribe$ = new Subject<void>();
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatPaginatorIntl, { static: false })
  paginatorintl: MatPaginatorIntl;
  constructor(
    public formBuilder: FormBuilder,
    private apiService: ApiService,
    private errorHandler: ErrorHandlerService,
    private router: Router,
    private httpClient: HttpClient,
    public msgdialogService: MsgdialogService,
    public dialog: MatDialog,

  ) {
    this.data = [];
    this.list = [];
  }
  baseUrl = "gate/api/";
  private users: User[] = [];
  // tslint:disable-next-line: max-line-length
  public displayedColumns = [
    "itcn_NR_INFOTECNICA",
    "itcn_NR_DIRETRIZ",
    "itcn_NR_SINTESE",
    "nucleo_TEC",
    "campo_1",
    "campo_2",
    "tecnico_CD",
    "dt_ELABORACAO",
    "detalhes",
    "download",
    "flag_EDITAR",
    "add",
  ];
  public Usuario;
  public filterOptionTodas: boolean = true;
  public filterLoading: boolean = false;
  public filterDT: boolean = false;
  public filterSI: boolean = false;
  public filterMyIT: boolean = false;
  public progress: number = 0
  public stilo
  /*
 "campo_1": "Contrato de apoio financeiro",
            "tecnico_CD": "ANDERSON PIRES VALGAS",
            "nucleo_TEC": "Núcleo Técnico de Engenharia",
            "campo_2": "Coleta de lixo",
            "flag_EDITAR": true,
            "itcn_DT_CADASTRO": "2020-04-08 00:00:00.0",
            "itcn_CDMATRICULA_CADASTRO": "00008521",
            "itcn_DK": 26970,
            "itcn_NR_INFOTECNICA": "308/2020"

*/
  @HostListener("mousedown", ["$event"])
  mouseHandling(event) {
    event.preventDefault();
    console.log("mousedown", event);
  }

  public formRangeDates = new FormGroup({
    FCDateStart: new FormControl(""),
    FCDateLimit: new FormControl(""),
  });

  ngOnInit() {
    this.filterEntity = new ListaFilter();
    this.apiService.getUser().subscribe((res: any) => {
      this.users = res;
    });
  }

  ngAfterViewInit(): void {
    //console.log("PagIndex", this.paginator.pageIndex);
    //console.log("PagIndex", this.paginator);
    //this.verTodasIts()

    this.verMinhasIts()
  }

  openModal(dk: string, nr: string) {
    this.dialog.open(UploadFilesComponent, {
      "width": window.innerWidth.toPrecision(),
      "maxHeight": '200vh',
      "data": {
        "ID": dk,
        "NR": nr
      },
      "autoFocus": false
    });
  }

  public verTodasIts = (currentPage = 0) => {
    this.httpClient
      .get(

        this.apiService.baseUrl +
        "listaItsPg?size=100&page=" + currentPage
      )

      .subscribe((res: any) => {
        this.paginator.length = res.totalElements;
        this.paginator.showFirstLastButtons = true;
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        // Adicionar dados à fonte de dados existente ou criar uma nova fonte de dados

        if (currentPage === 0) {
          this.filterLoading = true;
          this.dataSource.data = res.content;
          this.dataSourceOriginal = res.content;
          this.filterLoading = false;
        } else {
          this.dataSource.data = this.dataSource.data.concat(res.content);
          this.dataSourceOriginal = this.dataSourceOriginal.concat(res.content);
        }
        this.filterSI = false;
        this.filterDT = false;
        this.filterLoading = false;
        this.filterOptionTodas = true;
        this.filterMyIT = false;
        // Verificar se há mais páginas para buscar
        if (currentPage < res.totalPages) {
          // Fazer a próxima requisição recursivamente

          this.verTodasIts(currentPage + 1);


        }

        this.progress = ((currentPage) / res.totalPages) * 100

        this.stilo ={

            'margin-left': this.progress - 1 + '%;',
            'margin-bottom': '-20px',
            'background-color': '#5bc0de',
            'border-radius': '5px',
            'padding-left': '3px',
            'padding-right': '3px'

          }



      });

}
intervalId = {} as any;
//resps: Observable<null | string> = (null);
progressInLoading() {
  if (this.progress === 14) {
    debugger
    clearInterval(this.intervalId);
    //this.resps = "Item Loaded";
  }
  //const span = document.getElementById('cssprg');
  const margem = this.progress + '%;';



  console.log('Stilo', this.stilo)
  console.log('Loading: ' + this.progress + '% completed.');
}


  public verMinhasIts = () => {
  this.filterLoading = true;
  let currentPage = 0
  this.httpClient
    .get(
      this.apiService.baseUrl +
      "listaItsMatPg?size=" +
      this.paginator.pageSize * 100
    )

    .subscribe((res: any) => {
      console.log('listaITS',res)
      this.paginator.length = res.totalElements;
      this.paginator.showFirstLastButtons = true;
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.dataSource.data = res;
      this.dataSourceOriginal = res;
      this.filterDT = false;
      this.filterOptionTodas = false;
      this.filterLoading = false;
      this.filterMyIT = true;
      this.filterSI = false;
      currentPage = currentPage + 1
      this.progress = ((currentPage) / res.totalPages) * 100
    });

}

  public verDTS = () => {
  this.filterLoading = true;
  let currentPage = 0
  this.httpClient
    .get(
      this.apiService.baseUrl +
      "ListaDTs?size=" +
      this.paginator.pageSize * 100
    )
    .subscribe((res: any) => {
      // this.paginator.pageSize = res.totalElements

      this.paginator.length = res.totalElements;
      this.paginator.showFirstLastButtons = true;
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.dataSource.data = res.content;
      this.dataSourceOriginal = res.content;
      this.filterDT = true;
      this.filterSI = false;
      this.filterMyIT = false;
      this.filterOptionTodas = false;
      this.filterLoading = false;
      currentPage = currentPage + 1
      this.progress = ((currentPage) / res.totalPages) * 100
    });

}

public verSIs = () => {
  this.filterLoading = true;
  let currentPage = 0
  this.httpClient
    .get(
      this.apiService.baseUrl +
      "ListaSIs?size=" +
      this.paginator.pageSize * 100
    )
    .subscribe((res: any) => {
      // this.paginator.pageSize = res.totalElements

      this.paginator.length = res.totalElements;
      this.paginator.showFirstLastButtons = true;
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.dataSource.data = res.content;
      this.dataSourceOriginal = res.content;
      this.filterDT = false;
      this.filterSI = true;
      this.filterMyIT = false;
      this.filterOptionTodas = false;
      this.filterLoading = false;
      currentPage = currentPage + 1
      this.progress = ((currentPage) / res.totalPages) * 100
    });

}
  public getData(e,) {

  console.log(e.pageIndex);
  console.log(e);
  this.paginator.pageIndex = e.pageIndex + 1;
  console.log(this.paginator.pageIndex);
  console.log("PagIndex", this.paginator);
  if (e.pageIndex >= 3) {
    e.pageIndex = e.pageIndex + 1;
    e.previousPageIndex = e.pageIndex - 1;
    this.httpClient
      .get(
        this.apiService.baseUrl +
        "listaItsPg?size=" +
        this.paginator.pageSize * 2 +
        "&page=" +
        e.pageIndex
      )
      .subscribe((res: any) => {
        console.log("res", res);

        this.paginator.pageIndex = e.pageIndex + 1;
        this.paginator.length = res.totalElements;
        this.paginator.showFirstLastButtons = true;
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.dataSource.data = res.content;
      });
  }
}


  public redirectToEditar = (dk: string) => {
  this.router.navigate(["editar", dk]);
  console.log(dk);
};



  // tslint:disable-next-line: variable-name
  public redirectToDownLoad = (dk: string, itcn_NR_INFOTECNICA: string) => {
  this.apiService.downlodIt2(dk).subscribe((x) => {
    const newBlob = new Blob([x], { type: "application/pdf" });
    // tslint:disable-next-line: no-debugger
    const downloadURL = window.URL.createObjectURL(x);
    const link = document.createElement("a");
    link.href = downloadURL;
    const str = itcn_NR_INFOTECNICA;
    let newstr = str.replace("/", "_");
    newstr = "IT " + newstr + "_" + new Date().getTime() + ".pdf";
    link.download = newstr;
    link.click();
    window.URL.revokeObjectURL(downloadURL);
    link.remove();
  });
};

  public verDetalhesIt = (dk: string, itcn_NR_INFOTECNICA: string) => {
  this.apiService.detalharIt(dk).subscribe((x) => {
    const detalhamento = x;
    const dt2 = {
      titulo: "Detalhamento da IT",
      status: 'Modal',
      statusText: detalhamento,
    };
    //console.log('deltales', dt2)
    this.msgdialogService.openDialog(dt2);

  });
};

  public nestedFilterCheck(search, data, key) {
  if (typeof data[key] === 'object') {
    for (const k in data[key]) {
      if (data[key][k] !== null) {
        search = this.nestedFilterCheck(search, data[key], k);
      }
    }
  } else {
    search += data[key];
  }
  return search;
}

  public doFilter = (value: string) => {

  this.dataSource.filterPredicate = (data: [], filter: string): boolean => {
    const accumulator = (currentTerm, key) => {
      return this.nestedFilterCheck(currentTerm, data, key);
    };
    const dataStr = Object.keys(data).reduce(accumulator, '').normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
    // Transform the filter by normalize special chars, converting it to lowercase and removing whitespace.
    const transformedFilter = filter.trim().normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
    return dataStr.indexOf(transformedFilter) !== -1;
  };

  this.dataSource.filter = value.trim().normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "").toLocaleLowerCase();


  if (this.dataSource.paginator) {
    this.dataSource.paginator.firstPage();
  }
};

  public maskDate(t) {
  var v = this.formRangeDates.get(t).value;
  if (v.match(/^\d{2}$/) !== null) {
    var tDate = v + "/";
    this.formRangeDates.get(t).setValue(tDate);
  } else if (v.match(/^\d{2}\/\d{2}$/) !== null) {
    var tDate = v + "/";
    this.formRangeDates.get(t).setValue(tDate);
  }
  return;
}

  public formatFilterDate(dateStr) {
  var parts = dateStr.split("/");
  return parts[2] + "-" + parts[1] + "-" + parts[0] + " 00:00:00.0";
}

  public doFilterDate = (value) => {
  this.dataSource.data = this.dataSourceOriginal;

  if (this.formRangeDates.get("FCDateStart").value !== "") {
    var dateStart = this.formatFilterDate(
      this.formRangeDates.get("FCDateStart").value
    );
  } else {
    var dateStart = "";
  }
  if (this.formRangeDates.get("FCDateLimit").value !== "") {
    var dateLimit = this.formatFilterDate(
      this.formRangeDates.get("FCDateLimit").value
    );
  } else {
    var dateLimit = "";
  }

  if (dateStart !== "" && dateLimit !== "") {
    var filtered = this.dataSource.data.filter(function (item) {
      return (
        item['itcn_DT_CADASTRO'] >= dateStart &&
        item['itcn_DT_CADASTRO'] <= dateLimit
      );
    });
  }
  if (dateStart !== "" && dateLimit === "") {
    var filtered = this.dataSource.data.filter(function (item) {
      return item['itcn_DT_CADASTRO'] >= dateStart;
    });
  }
  if (dateStart === "" && dateLimit !== "") {
    var filtered = this.dataSource.data.filter(function (item) {
      console.log(item);
      return item['itcn_DT_CADASTRO'] <= dateLimit;
    });
  }
  if (filtered) {
    this.dataSource.data = filtered;
  } else {
    this.dataSource.data = this.dataSourceOriginal;
  }
};
}
